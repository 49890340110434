@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

/* It makes the HTML progress bar filling smooth when value change. */
progress::-webkit-progress-value {
  transition: 0.6s width ease-out;
}

@layer base {
  .btn-gradient {
    @apply !bg-gradient !bg-[length:300%_300%] hover:saturate-[1.2] shadow duration-100 !border-0 !border-transparent !bg-transparent animate-shimmer disabled:!bg-none disabled:!bg-gray-500/30  !text-white;
  }
  .btn {
    @apply !capitalize;
  }
}

@layer components {
  .loading-spinner {
    @apply flex justify-center items-center py-20 text-xl text-gray-600;
  }
}

@layer utilities {
  .div-markdown-color h1,
  .div-markdown-color h2,
  .div-markdown-color h3,
  .div-markdown-color h4,
  .div-markdown-color h5,
  .div-markdown-color h6 {
    @apply text-gray-900;
  }

  .div-markdown-color p,
  .div-markdown-color li {
    @apply text-gray-700;
  }

  .div-markdown-color a {
    @apply text-blue-600 hover:text-blue-800 transition-colors duration-200;
  }
}

@layer components {
  .product-card {
    @apply bg-white shadow-md rounded-lg p-6;
  }
}

@layer base {
  body {
    @apply text-gray-900 bg-gray-50 font-sans;
  }
  h1 {
    @apply text-4xl font-bold mb-4;
  }
  h2 {
    @apply text-2xl font-semibold mb-3;
  }
}

@layer components {
  .content-layout {
    @apply grid grid-cols-1 md:grid-cols-2 gap-8;
  }
}
